// src/components/QuestionList.js
import React from 'react';
import { ListGroup} from 'react-bootstrap';
import QuestionCard from './QuestionCard';

function QuestionList({ selectedTags, setSelectedTags, questionsData }) {
  // console.log('questionsData', questionsData)
  const { count, questions } = questionsData;
  // console.log('questions', questions);

  let foundText = (tags, count) => {
    if (tags.length === 0){
      return ""
    }
    else {
      return `found ${count} UPSC Questions for ${tags.join(', ')}`
    }
  };

  return (
    <div className="mt-3">
      <h5> {foundText(selectedTags, count)} </h5>
      {selectedTags.length === 0 ? (
        <p>Enter a tag to see questions</p>
      ) : (
        <ListGroup>
          {/* {console.log(questions)} */}
          {questions.map((question, index) => (
            <QuestionCard key={index} index={index} question={question} selectedTags = {selectedTags} setSelectedTags={setSelectedTags} />
          ))}
        </ListGroup>
      )}
    </div>
  );
}

export default QuestionList;
