import React, { useState } from 'react';
import { Card, Form, Accordion, Badge } from 'react-bootstrap';

function QuestionCard({ index, question, selectedTags, setSelectedTags }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const updateTags = (new_tag) => {
    if (!selectedTags.includes(new_tag)) {
      setSelectedTags([...selectedTags, new_tag]);
    }

  };

  function toTitleCase(str) {
  return str
    .toLowerCase() // Convert the string to lowercase
    .split(" ") // Split the string into an array of words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the array back into a string
}

  return (
    <Card
      className="mb-3"
      style={{
        backgroundColor: '#f8f8f8', // Light gray background for the entire card
        borderRadius: '12px',
        border: '1px solid #f8f8f8',
        padding: '15px',
      }}
    >
      <Card.Body>
        <div
          className="d-flex align-items-center mb-3"
          style={{
            // backgroundColor: '#e7f3ff', // Light blue background behind Q
            borderRadius: '8px',
            // padding: '8px 12px',
          }}
        >
          <h5
            style={{
              backgroundColor: '#ebebeb', // Slightly darker blue for Q background
              borderRadius: '20%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '10px',
              fontSize: '18px',
            }}
          >
            {`Q${index + 1}`}
          </h5>
          <p className="mb-0" style={{ fontWeight: '600', fontSize: '16px' }}>
            {question.question}
          </p>
        </div>
        <Form>
          {question.options.map((option, idx) => (
            <div
              key={idx}
              className="d-flex align-items-center mb-2"
              style={{
                // border: '1px solid #e6e6e6',
                // borderRadius: '6px',
                // padding: '10px',
                // backgroundColor: '#ffffff',
                cursor: 'pointer',
              }}
            >
              <Form.Check
                type="radio"
                id={`option-${idx}`}
                label={option}
                name={`question-${question.id}`}
                className="me-2"
                checked={selectedOption === idx}
                onChange={() => setSelectedOption(idx)}
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
          ))}
        </Form>

        <Accordion className="mt-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Select one option to reveal the answer
            </Accordion.Header>
            <Accordion.Body
              style={{
                backgroundColor: '#f8f9fb',
                borderRadius: '8px',
                padding: '12px',
                border: '1px solid #e6e6e6',
              }}
            >
              {selectedOption !== null
                ? `(${question.correct_choice}) ${question.explanation}`
                : 'Please select an option to reveal the answer.'}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="mt-3">
          {question.tags.map((tag, index) => (
 <Badge 
  key={index} bg=''
  style={{backgroundColor: '#ededed', 
    color:'#101010',
    cursor: 'pointer'
  }}
  ariant="dot"

  onClick={() => updateTags(tag)}
>
  {toTitleCase(tag)}
  
</Badge> 



          ))}

        </div>
      </Card.Body>
    </Card>
  );
}

export default QuestionCard;
