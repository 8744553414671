import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import SearchBar from "./components/SearchBar";
import QuestionList from "./components/QuestionList";
import Onboarding from "./components/Onboarding";
// import TurnstileComponent from "./components/TurnstileComponent"; // Import Turnstile Component
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
//import Onboarding from "./firebase";

function App() {
  const [selectedTags, setSelectedTags] = useState([]);
  const [questionsData, setQuestionsData] = useState({ count: 0, questions: [] });
  const [selectedExam, setSelectedExam] = useState("upsc");
  // const [turnstileToken, setTurnstileToken] = useState(null); // State for Turnstile token

  const navigate = useNavigate();
  const { exam } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (exam) setSelectedExam(exam);

    const tagsParam = searchParams.get("tags");
    if (tagsParam) {
      const tagsArray = tagsParam.split(",");
      setSelectedTags(tagsArray);
    }
  }, [exam, searchParams]);

  useEffect(() => {
    const tagsQuery = selectedTags.length ? `?tags=${selectedTags.join(",")}` : "";
    navigate(`/${selectedExam}${tagsQuery}`, { replace: true });
  }, [selectedExam, selectedTags, navigate]);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (selectedTags.length === 0) {
        setQuestionsData({ count: 0, questions: [] });
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/questions`, {
          params: { tags: selectedTags.join(",") },
        });
        setQuestionsData(response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [selectedTags]);

   // Function to create a string with an Oxford comma
  const formatTagsWithOxfordComma = (tags) => {
    if (tags.length === 0) return "";
    if (tags.length === 1) return tags[0];
    if (tags.length === 2) return tags.join(" and ");
    return `${tags.slice(0, -1).join(", ")}, and ${tags[tags.length - 1]}`;
  };

  useEffect(() => {
    // document.title = `${selectedExam.toUpperCase()} {} Questions - Turtle Rabbit`;
    // Update document title
    const formattedTags = formatTagsWithOxfordComma(selectedTags);
    document.title = `${selectedExam.toUpperCase()} ${formattedTags} PYQs - Turtle Rabbit`;
  }, [selectedExam, selectedTags]);

  // const handleTurnstileToken = (token) => {
  //   console.log("Turnstile token received in App:", token);
  //   setTurnstileToken(token);

  //   // Optionally, send the token to your backend for validation
  //   // axios.post('/api/verify-turnstile', { token }).then(response => console.log(response.data));
  // };

  const handleLogoClick = () => {
    setSelectedTags([]);
  };

  return (
    <Container className="pt-3">
      <div className="text-center mb-3">
        <Link to="/" onClick={handleLogoClick}>
          <img src="/turtlerabbit_logo.png" alt="Logo" style={{ maxWidth: "200px", height: "auto" }} />
        </Link>
      </div>


      {/* <TurnstileComponent onVerify={handleTurnstileToken} />  */}

      <SearchBar
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        selectedExam={selectedExam}
        setSelectedExam={setSelectedExam}
      />
      <QuestionList selectedTags={selectedTags} setSelectedTags={setSelectedTags} questionsData={questionsData} />
    </Container>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/:exam" element={<App />} />
        <Route path="/onboarding" element={<Onboarding />} />
      </Routes>
    </Router>
  );
}
