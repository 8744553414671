import React, { useState } from 'react';
import { Form, Row, Col, Dropdown, DropdownButton, InputGroup, ListGroup, Badge } from 'react-bootstrap';
import axios from 'axios';

console.log('Current API URL:', process.env.REACT_APP_API_URL);

function SearchBar({ selectedTags, setSelectedTags, selectedExam, setSelectedExam }) {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    fetchSuggestions(value);
  };

  const fetchSuggestions = async (value) => {
    if (!value) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    try {
      // console.log(value);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggest-tags-with-freq?query=${value}`);
      // console.log('Fetched suggestions:', response.data); // Debugging
      setSuggestions(response.data || []);
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(''); // Clear the search input
    setShowSuggestions(false);

    // Add tag if not already selected
    if (!selectedTags.includes(suggestion)) {
      setSelectedTags([...selectedTags, suggestion['Tag']]);
    }
  };

  const removeTag = (tag) => {
    setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
  };

  const handleExamSelect = (exam) => {
    setSelectedExam(exam);
  };

  return (
    <Col>
      <Row className="mb-3">
        <Col>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Type a topic to search UPSC Prelims PYQs (e.g. 'Right to Education Act', 'Buddhism', 'RBI')"
              value={query}
              onChange={handleInputChange}
              onFocus={() => setShowSuggestions(true)}
              onTouchStart={() => setShowSuggestions(true)} // Touch event for mobile
            />
            <InputGroup.Text>
              <i className="bi bi-search" style={{ cursor: 'pointer' }} onClick={() => console.log('Search clicked!')}></i>
            </InputGroup.Text>
          </InputGroup>

          {showSuggestions && suggestions.length > 0 && (
            <ListGroup
              className="position-absolute"
              style={{ zIndex: 1000, maxHeight: '200px', overflowY: 'auto', backgroundColor: 'white' }}
            >
              {suggestions.map((suggestion, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion['Tag']} ({suggestion['Freq']})
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col xs="auto">
          <DropdownButton id="dropdown-basic-button" title={selectedExam} onSelect={handleExamSelect}>
            <Dropdown.Item href="#" eventKey='upsc'>UPSC</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      <Row>
        <div>
          {selectedTags.map((tag, index) => (
            <Badge key={index} pill bg="info" className="me-2">
              {tag} <i className="bi bi-x" style={{ cursor: 'pointer' }} onClick={() => removeTag(tag)}></i>
            </Badge>
          ))}
        </div>
      </Row>
    </Col>
  );
}

export default SearchBar;
