import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import {jwtDecode as jwt_decode} from "jwt-decode"; // Fixed import for jwt-decode

import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";

const Onboarding = () => {
  // Callback function for Google login
  const handleGoogleSuccess = (response) => {
    console.log("Google login success:", response);
    try {
      const decodedUserInfo = jwt_decode(response.credential); // Decode Google user information
      console.log("Google User Information:", decodedUserInfo);
    } catch (error) {
      console.error("Error decoding Google token:", error);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google login failed:", error);
  };

  // Callback function for Facebook login
  const handleFacebookResponse = (response) => {
    console.log("Facebook response:", response);
    if (response?.data?.accessToken) {
      console.log("Facebook login success:", response);
      console.log("Facebook User Information:", {
        name: response.data.name,
        email: response.data.email,
        picture: response.data.picture.data.url,
      });
    } else {
      console.error("Facebook login failed or canceled.");
    }
  };

  return (
    <GoogleOAuthProvider clientId="992395142453-ld3snqk3f6ikmfd62rp17p0ei5v4hag7.apps.googleusercontent.com">
      <div
        className="vh-100 d-flex flex-column text-white"
        style={{
          backgroundImage: 'url("/background.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        {/* Top Section: Title and Subtitle */}
        <div className="d-flex flex-column align-items-center justify-content-start mt-5">
          <h1 className="mb-3 text-center">Explore and learn new skills</h1>
          <p className="mb-4 text-center">
            Join Question Box to learn and grow your skill about exams
          </p>
        </div>

        {/* Spacer to push buttons to the bottom */}
        <div className="flex-grow-1"></div>

        {/* Bottom Section: Login Buttons */}
        <div
          className="d-flex flex-column align-items-center justify-content-end mb-5"
        >
          <div
            className="d-grid gap-3"
            style={{ width: "100%", maxWidth: "400px" }}
          >
            {/* Facebook Login Button */}
            <LoginSocialFacebook
              appId="3826336960980995" // Replace with your Facebook App ID
              onResolve={handleFacebookResponse}
              onReject={(error) => console.error("Facebook login error:", error)}
            >
              <FacebookLoginButton />
            </LoginSocialFacebook>

            {/* Google Login Button */}
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleFailure}
              useOneTap
              render={(renderProps) => (
                <button
                  className="btn btn-outline-light btn-lg w-100"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <i className="bi bi-google me-2"></i> Connect with Google
                </button>
              )}
            />

            {/* Email Signup Button */}
            <button className="btn btn-success btn-lg w-100">
              Sign up with email
            </button>
          </div>
          <p className="mt-3 text-center">
            Already have an account?{" "}
            <a href="/login" className="text-success">
              Log In
            </a>
          </p>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Onboarding;
